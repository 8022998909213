import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

import { Select as NhsUkSelect } from 'nhsuk-react-components';

const Select = forwardRef((props, ref) => {
    const [error, setError] = useState(undefined);
    const [isReadOnly, setIsReadOnly] = useState(undefined);
    const [isValid, setIsValid] = useState(undefined);

    useEffect(() => {
        setIsReadOnly(props.question.isReadOnly);
    }, [props.question.isReadOnly]);

    useImperativeHandle(ref, () => ({
        validate,
        isValid
    }));

    const validate = () => {
        if (props.question.mandatory) {
            if (!props.question.value) {
                setError(`Enter the ${props.question.name}`);
                setIsValid(false);
            } else {
                setError(undefined);
                setIsValid(true);
            }
        } else {
            setError(undefined);
            setIsValid(true);
        }
    };

    const handleChange = async event => {
        await props.onChange({
            questionId: props.question.id,
            linked_questions: props.question.linked_questions || null,
            option: props.question.options.find(x => x.key === event.target.value) || null,
            value: event.target.value
        });

        props.question.value = event.target.value;

        validate();
    };

    const isInOptions = props.question.options.some(obj => obj.key === props.question.value);
    var value;
    if (isInOptions) {
        value = props.question.value;
    } else {
        value = '';
    }

    return (
        <NhsUkSelect
            label={props.question.name}
            hint={props.question.hint_text}
            value={value}
            onChange={handleChange}
            onBlur={handleChange}
            error={error}
            disabled={isReadOnly}
            required={props.question.mandatory}
        >
            <NhsUkSelect.Option value="">Please Select</NhsUkSelect.Option>
            {props.question.options.map(option => (
                <NhsUkSelect.Option key={option.key} value={option.key}>
                    {option.value}
                </NhsUkSelect.Option>
            ))}
        </NhsUkSelect>
    );
});

export default Select;
